import React, { useEffect } from 'react'

//css
import '../EnterpriseSecurity.scss'
import CommonSecuritycontainer from '../CommonSecuritycontainer/CommonSecuritycontainer';
import images from '../../../../../Assets/Images';
import StickyHeader from '../../Header/StickyHeader';
import Footer from '../../Footer/Footer';
import { PrivacyNoticeData } from '../../../commonSecurityData';

const PrivacyNotice = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='security-and-trust'>
      <StickyHeader />
      <div>
        <CommonSecuritycontainer image={images.PrivacyNotice} title='Privacy Notice' />
        <div className='security-and-trust-main-div common-container'>
          {/* <div className='img-div w-100 d-flex align-items-center'>
            <img src={images.PrivacyNotice} alt="Privacy Notice" className='w-100' />
          </div> */}
          <div className="privacy-notice mt-4">
            {PrivacyNoticeData.map((section: any, index: any) => (
              <div key={index} className="security-section">
                <div className='security-title'>
                  <h1 className="header-title">{section.headerTitle}</h1>
                </div>
                {section.type === 'para' ? (
                  <div className='security-para mb-2'>
                    <p>{section.details} <span className='contact-info'>{section?.email && section?.email}</span></p>
                  </div>
                ) : (
                  section.subData?.map((subSection: any, subIndex: any) => (
                    <div key={subIndex} className="sub-section">
                      {subSection.title && <h3 className="privacy-sub-title mb-3 mt-5">{subSection.title}</h3>}
                      <div className='security-para mb-2'>
                        <p>{subSection?.reference}</p>
                      </div>
                      <ol className="details-list">
                        {subSection.details?.map((item: any, detailIndex: any) => (
                          <li key={detailIndex}>
                            {subSection.listTitle && subSection.listTitle[detailIndex] ? (
                              <strong>{subSection.listTitle[detailIndex]}: </strong>
                            ) : null}
                            {item}
                            {Array.isArray(subSection.subDetails) && subSection.subDetails[detailIndex] && (
                              <ul>
                                {subSection.subDetails[detailIndex].map((subItem: any, subItemIndex: any) => (
                                  <li key={subItemIndex}>{subItem}</li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ol>
                      {subSection?.reference1 &&
                        <div className='security-para mt-2'>
                          <p>{subSection?.reference1}</p>
                        </div>
                      }
                    </div>
                  ))
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyNotice
