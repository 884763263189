import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import axios from "axios";

interface storeClinicalGenericOnboardingArgs {
    data: any;
}

export const storeClinicalGenericOnboarding = createAsyncThunk<any, storeClinicalGenericOnboardingArgs, { state: RootState }>(
    "onboarding/storeClinicalGenericOnboarding",
    async ({ data }, { dispatch }) => {
        try {
            const response: any = await axios.post(ApiUrl.onboarding.create_generic_clinical_onboarding, data);
            if (response) {
                return response;
            }
        } catch (error) {
            throw error;
        }
    }
)