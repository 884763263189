import React from 'react'

import './ContactSales.scss'
import StickyHeader from '../Header/StickyHeader'
import Footer from '../Footer/Footer'
import images from '../../../../Assets/Images'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import CBSelect from '../../CBSelect/CBSelect'
import { option } from '../../../../Pages/OnBoarding/components/Helpers/OptionArray'
import { formatPhoneNumber } from '../../../utils'
import FAQComponent from '../FAQComponent/FAQComponent'
import { useLocation } from 'react-router-dom'

const ContactSales = () => {

    const location = useLocation()

    const initialValues = {
        name: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        title: '',
        select: '',
        information: '',
        providers: '',
        ehr: ''
    }

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Work email is invalid').required('Work email is required'),
        phoneNumber: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be in the format (XXX) XXX-XXXX').required('Phone number is required'),
        companyName: Yup.string().required('Company Name is required'),
        title: Yup.string().required('Title is required'),
        select: Yup.string(),
        information: Yup.string(),
        providers: location.pathname === '/request-demo' ? Yup.string() : Yup.string().nullable(),
        ehr: location.pathname === '/request-demo' ? Yup.string() : Yup.string().nullable(),
    })

    const handleSubmit = (value: any) => {
        console.log("🚀 ~ handleSubmit ~ value:", value)
    }

    return (
        <div className='contact-sales-page'>
            <StickyHeader />
            <div className='contact-sales-form'>
                <div>
                    <div className="contact-page d-flex justify-content-center">
                        <div className="first first-bg">
                            <img src={images.PricingBg} alt="" />
                        </div>
                        <div className="second second-bg">
                            <img src={images.PricingBg} alt="" />
                        </div>
                        {
                            location.pathname === '/request-demo' ?

                                <div className="contact-form-div position-absolute common-container w-100">
                                    <h1>Request a Demo</h1>
                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                        {(formik) => (
                                            <Form className='contact-form'>
                                                <div>
                                                    <label className='form-label'>Name*</label>
                                                    <Field
                                                        type='text'
                                                        name='name'
                                                    />
                                                    <ErrorMessage name='name' component='div' className='error-message text-danger' />
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className='form-label'>Email*</label>
                                                        <Field
                                                            type='text'
                                                            name='email'
                                                        />
                                                        <ErrorMessage name='email' component='div' className='error-message text-danger' />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label'>Phone Number*</label>
                                                        <Field
                                                            type='text'
                                                            name='phoneNumber'
                                                            onChange={(e: any) => {
                                                                const formattedValue = formatPhoneNumber(e.target.value);
                                                                formik.setFieldValue('phoneNumber', formattedValue);
                                                            }}
                                                        />
                                                        <ErrorMessage name='phoneNumber' component='div' className='error-message text-danger' />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className='form-label'>Company Name*</label>
                                                        <Field
                                                            type='text'
                                                            name='companyName'
                                                        />
                                                        <ErrorMessage name='companyName' component='div' className='error-message text-danger' />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label'>Title*</label>
                                                        <Field
                                                            type='text'
                                                            name='title'
                                                        />
                                                        <ErrorMessage name='title' component='div' className='error-message text-danger' />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className='form-label'>I am a</label>
                                                        <CBSelect options={option} className='request-form w-100' placeholder='Select Role' onChange={(option: string) => {
                                                            formik.setFieldValue('select', option);
                                                        }} />
                                                        <ErrorMessage name='select' component='div' className='error-message text-danger' />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className='form-label'>How many providers work for your organization?</label>
                                                        <Field
                                                            type='text'
                                                            name='providers'
                                                        />
                                                        <ErrorMessage name='providers' component='div' className='error-message text-danger' />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label>What EHR are you using?</label>
                                                    <Field
                                                        type='text'
                                                        name='ehr'
                                                    />
                                                    <ErrorMessage name='ehr' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>Additional Information</label>
                                                    <Field
                                                        as="textarea"
                                                        type='text'
                                                        name='information'
                                                    />
                                                    <ErrorMessage name='information' component='div' className='error-message text-danger' />
                                                </div>
                                                <div className='text-center' style={{ marginTop: "22px" }}>
                                                    <button className='rounded-save-theme-btn'>Submit</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                :
                                <div className="contact-form-div position-absolute common-container w-100">
                                    <h1>{location.pathname === '/contact-us' ? 'Contact Us' : 'Contact Sales'}</h1>
                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                        {(formik) => (
                                            <Form className='contact-form'>
                                                <div>
                                                    <label className='form-label'>Name*</label>
                                                    <Field
                                                        type='text'
                                                        name='name'
                                                    />
                                                    <ErrorMessage name='name' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>Work Email*</label>
                                                    <Field
                                                        type='text'
                                                        name='email'
                                                    />
                                                    <ErrorMessage name='email' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>Phone Number*</label>
                                                    <Field
                                                        type='text'
                                                        name='phoneNumber'
                                                        onChange={(e: any) => {
                                                            const formattedValue = formatPhoneNumber(e.target.value);
                                                            formik.setFieldValue('phoneNumber', formattedValue);
                                                        }}
                                                    />
                                                    <ErrorMessage name='phoneNumber' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>Company Name*</label>
                                                    <Field
                                                        type='text'
                                                        name='companyName'
                                                    />
                                                    <ErrorMessage name='companyName' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>Title*</label>
                                                    <Field
                                                        type='text'
                                                        name='title'
                                                    />
                                                    <ErrorMessage name='title' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>I am a</label>
                                                    <CBSelect options={option} className='onbarding-dropdown w-100' placeholder='Select Role' onChange={(option: string) => {
                                                        formik.setFieldValue('select', option);
                                                    }} />
                                                    <ErrorMessage name='select' component='div' className='error-message text-danger' />
                                                </div>
                                                <div>
                                                    <label className='form-label'>Additional Information</label>
                                                    <Field
                                                        as="textarea"
                                                        type='text'
                                                        name='information'
                                                    />
                                                    <ErrorMessage name='information' component='div' className='error-message text-danger' />
                                                </div>
                                                <div className='text-center' style={{ marginTop: "22px" }}>
                                                    <button className='rounded-save-theme-btn'>Submit</button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                        }
                    </div>
                    <div className='extra-div'></div>
                    <div className='common-container faq-component'>
                        <FAQComponent />
                        <h1 className='free-trial'>Your own branded Virtual Care Center in <span>5 minutes for free!</span></h1>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactSales