import { faArrowRotateRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Amplify, Auth } from 'aws-amplify';
import { setLocalStorage } from '../../../../Components/Patient/Utils/AuthHandlers';
import { AdminAwsConfig, ProviderAwsConfig } from '../../../../awsConfig';
import { EyeClose, EyeOpen } from '../../../../Assets/Svgs';
import { ToastMessage } from '../../../../Components/Common/ToastMessage';
import { useDispatch } from 'react-redux';
import { storeIndividualGenericOnboarding } from '../../../../Store/Thunk/Onboarding/storeIndividualGenericOnboarding';


const Step1 = ({ handleBackButtonClick, handleCheckoutButtonClick, handleClose, planName }: any) => {

    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false);

    if (planName === 'individual') {
        Amplify.configure({
            Auth: {
                region: ProviderAwsConfig.REGION,
                userPoolId: ProviderAwsConfig.USER_POOL_ID,
                userPoolWebClientId: ProviderAwsConfig.USER_POOL_CLIENT_ID,
            },
        });
    } else {
        Amplify.configure({
            Auth: {
                region: AdminAwsConfig.REGION,
                userPoolId: AdminAwsConfig.USER_POOL_ID,
                userPoolWebClientId: AdminAwsConfig.USER_POOL_CLIENT_ID,
            },
        });
    }

    const [isSendVerification, setIsSendVerification] = useState(false);

    const initialValues: any = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        // verificationCode: '',
        provider: false,
        termsAndConditions: false
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().test('is-required', 'First name is a required field', function (value) {
            const { isSendVerification }: any = this.options.context;
            if (!isSendVerification) {
                return Yup.string().required().isValidSync(value);
            }
            return true;
        }),

        lastName: Yup.string().test('is-required', 'Last name is a required field', function (value) {
            const { isSendVerification }: any = this.options.context;
            if (!isSendVerification) {
                return Yup.string().required().isValidSync(value);
            }
            return true;
        }),

        email: Yup.string()
            .email('Email address is not valid')
            .required('Email is a required field'),

        password: Yup.string().test('is-required', 'Password is a required field', function (value) {
            const { isSendVerification }: any = this.options.context;
            if (!isSendVerification) {
                return Yup.string()
                    .min(8, 'Password must be at least 8 characters')
                    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
                    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
                    .matches(/\d/, 'Password must contain at least one number')
                    .matches(/[\W_]/, 'Password must contain at least one symbol')
                    .required()
                    .isValidSync(value);
            }
            return true;
        }),

        verificationCode: Yup.string().test('is-required', 'Verification code is required', function (value) {
            const { isSendVerification }: any = this.options.context;
            if (isSendVerification) {
                return Yup.string().required().isValidSync(value);
            }
            return true;
        }),

        provider: Yup.boolean().oneOf([true], 'You must be or represent a healthcare provider to continue.'),

        termsAndConditions: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
    });

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        setSubmitting(true);
        if (isSendVerification) {
            toast.loading('Please wait...');
            try {
                await Auth.confirmSignUp(values.email, values.verificationCode);
                await Auth.signIn(values.email, values.password, { type: planName === 'individual' ? 'provider' : 'admin' });
                const userData = await Auth.currentAuthenticatedUser();
                console.log("🚀 ~ handleSubmit ~ userData:", userData)
                if (userData) {
                    setLocalStorage("SignUpUserName", values.firstName + " " + values.lastName);
                    setLocalStorage("data",
                        JSON.stringify({
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: userData.attributes.email,
                            provider_acknowledgement: values.provider,
                            terms_agreement_acknowledgement: values.termsAndConditions
                        })
                    );
                    setLocalStorage("userData", JSON.stringify(userData.signInUserSession.accessToken.payload));
                    setLocalStorage("userToken", userData.signInUserSession.idToken.jwtToken);

                    const payload = {
                        account_id: userData.attributes.sub,
                        email: values.email,
                        first_name: values.firstName,
                        last_name: values.lastName,
                        provider_acknowledgement: values.provider,
                        terms_agreement_acknowledgement: values.termsAndConditions,
                    }
    
                    if (planName === 'individual') {
                        const response: any = await dispatch(storeIndividualGenericOnboarding({ data: payload }));
                        if (response?.payload?.status === 200) {
                            toast.dismiss();
                            ToastMessage("success", JSON.parse(response.payload.data).message);
                            setIsSendVerification(true);
                        } else {
                            toast.dismiss();
                            ToastMessage("error", JSON.parse(response.payload.data).error);
                        }
                    }
                }
                toast.dismiss();
                ToastMessage("success",'Verification successful!');
                handleCheckoutButtonClick();
            } catch (error) {
                toast.dismiss();
                ToastMessage("error",'Verification failed. Please try again.');
            }
        } else {
            toast.loading('Verification code sending...')
            try {
                await Auth.signUp({
                    username: values.email,
                    password: values.password,
                    attributes: {
                        name: values.firstName + ' ' + values.lastName,
                        email: values.email,
                        profile: 'provider'
                    },
                });
                toast.dismiss();
                ToastMessage("success",'Verification code sent successfully!');
                setIsSendVerification(true);
            } catch (error:any) {
                toast.dismiss();
                ToastMessage("error",error.message);
            }
        }
        setSubmitting(false);
    };

    const handleResendCode = async (email: string) => {
        toast.loading('Resending code...');
        try {
            await Auth.resendSignUp(email);
            toast.dismiss();
            ToastMessage("success", 'Verification code sent successfully!');
        } catch (error) {
            toast.dismiss();
            ToastMessage("error", 'Failed to resend verification code. Please try again.');
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <div className='w-100'>
            <div className='d-flex onboarding-main-flow'>
                <div className='d-flex flex-column onboarding-stepping' style={{ gap: '24px' }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        context={{ isSendVerification }}
                    >
                        {({ values, isSubmitting, isValid }) => (
                            <Form id='step1-form'>
                                {!isSendVerification && (
                                    <div className='onboarding-step'>
                                        <div className="row">
                                            <div className='col-md-12 pb-4'>
                                                <div className="form-group position-relative">
                                                    <label className='onboarding-user'>Email*</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control w-100 ${values.email.length > 0 ? '' : 'input-user'}`}
                                                        name="email"
                                                        placeholder='Enter email'
                                                        autoComplete='new-email'
                                                    />
                                                    <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${values.email.length > 0 ? 'check' : 'd-none'}`} />
                                                </div>
                                                <ErrorMessage name="email" component="div" className="text-danger error pt-2" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-lg-6 col-md-6 pb-4'>
                                                <div className="form-group position-relative">
                                                    <label className='onboarding-user'>First Name*</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control w-100 ${values.firstName.length > 0 ? '' : 'input-user'}`}
                                                        name="firstName"
                                                        placeholder='Enter first name'
                                                    />
                                                    <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${values.firstName.length > 0 ? 'check' : 'd-none'}`} />
                                                </div>
                                                <ErrorMessage name="firstName" component="div" className="text-danger error pt-2" />
                                            </div>
                                            <div className='col-lg-6 col-md-6 pb-4'>
                                                <div className="form-group position-relative">
                                                    <label className='onboarding-user'>Last Name*</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control w-100 ${values.lastName.length > 0 ? '' : 'input-user'}`}
                                                        name="lastName"
                                                        placeholder='Enter last name'
                                                    />
                                                    <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${values.lastName.length > 0 ? 'check' : 'd-none'}`} />
                                                </div>
                                                <ErrorMessage name="lastName" component="div" className="text-danger error pt-2" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className='onboarding-user'>Password*</label>
                                            <div className="position-relative">
                                                <Field
                                                    type={showPassword ? "text" : "password"}
                                                    className={`form-control w-100 ${values.password.length > 0 ? '' : 'input-user'}`}
                                                    name="password"
                                                    placeholder='Enter password'
                                                    autoComplete='new-password'
                                                />
                                                <img
                                                    src={showPassword ? EyeOpen : EyeClose}
                                                    className="position-absolute cursor-pointer"
                                                    style={{ right: "15px", bottom: "13px" }}
                                                    onClick={handleTogglePasswordVisibility}
                                                    alt=""
                                                />
                                            </div>
                                            <ErrorMessage name="password" component="div" className="text-danger error pt-2" />
                                            <div className='pt-2 password-suggetion'>Must be 8 or more characters, use a number, symbol and uppercase and lowercase letters</div>
                                        </div>
                                        <div className='condition-checkbox pt-4'>
                                            <Field
                                                type="checkbox"
                                                className=''
                                                name="provider"
                                            /> <p>I am using CentBlick as a healthcare provider, not a patient</p>
                                        </div>
                                        <ErrorMessage name="provider" component="div" className="text-danger error pt-2" />
                                        <div className='condition-checkbox pt-4'>
                                            <Field
                                                type="checkbox"
                                                className=''
                                                name="termsAndConditions"
                                            /> <p>I agree to the <a target='_blank' href='https://www.centiblick.com/terms-of-service'>Terms of use</a> </p>
                                        </div>
                                        <ErrorMessage name="termsAndConditions" component="div" className="text-danger error pt-2" />
                                    </div>
                                )}
                                {isSendVerification && (
                                    <div className='onboarding-step'>
                                        {/* Verification fields */}
                                        <div className="row">
                                            <div className='col-md-12 pb-3'>
                                                <div className="form-group position-relative">
                                                    <label className='onboarding-user'>Verification Code*</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control w-100 ${values.verificationCode?.length > 0 ? '' : 'input-user'}`}
                                                        name="verificationCode"
                                                        placeholder='Enter verification code'
                                                    />
                                                </div>
                                                <ErrorMessage name="verificationCode" component="div" className="text-danger error pt-2" />
                                            </div>
                                            <div>
                                                <button type="button" className="save-theme-btn d-flex align-items-center gap-2 fw-normal" style={{ padding: "12px 16px" }} onClick={() => handleResendCode(values.email)}>
                                                    <FontAwesomeIcon icon={faArrowRotateRight} /> Resend Code
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='d-flex justify-content-between cliniconboarding-btns w-100 pt-4'>
                                    <button className='onboarding-back main-delete-theme-btn' onClick={() => { handleBackButtonClick(); handleClose(); }}>Back</button>
                                    <button className='onboarding-checkout yes-theme-btn' type='submit' disabled={!values.termsAndConditions || !values.provider}>Continue</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div >
    );
};

export default Step1;
