import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { updatedPost } from "../../../Network/ApiService";

interface storeIndividualGenericOnboardingArgs {
    data: any;
}

export const storeIndividualGenericOnboarding = createAsyncThunk<any, storeIndividualGenericOnboardingArgs, { state: RootState }>(
    "onboarding/storeIndividualGenericOnboarding",
    async ({ data }, { dispatch }) => {
        try {
            const response: any = await updatedPost({ url: ApiUrl.onboarding.create_generic_individual_onboarding, data });
            if (response) {
                return response;
            }
        } catch (error) {
            throw error;
        }
    }
)