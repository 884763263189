import React from 'react'

interface IdentityProps {
    handleBackButtonClick: () => void;
    handleCheckoutButtonClick: () => void;
    planName?: 'individual' | 'clinic';
}

const IdentityComponent = ({ handleBackButtonClick, handleCheckoutButtonClick, planName }: IdentityProps) => {
    return (
        <div className='w-100 d-flex justify-content-center mt-4'>
            <div className='d-flex flex-column identity-stepping'>
                <h1 className='fw-bold'>Thank you for verifying your identity!</h1>
                <p className='my-4'>Once your purchase is complete, you’ll receive an email with instructions to finalize your registration and gain access to the CentiBlick platform.</p>
                <h1 className='fw-bold'>A CentiBlick Solution Specialist will be in touch soon to:</h1>
                <ol>
                    <li>Personalize your platform with your chosen service name and practice colors</li>
                    <li>Provide any training you may need</li>
                    <li>Assist with integrating patient access on your website</li>
                </ol>
                <p className='mt-4'>Welcome to CentiBlick – we're here to support you every step of the way!</p>

                <div className='d-flex justify-content-between cliniconboarding-btns w-100 mt-4'>
                    <button className='onboarding-back main-delete-theme-btn' onClick={() => handleBackButtonClick()}>Back</button>
                    <button className='onboarding-checkout yes-theme-btn' onClick={()=>handleCheckoutButtonClick()}>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default IdentityComponent