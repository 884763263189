import React, { useState } from 'react';
import { LandingLogo } from '../../../../Assets/Svgs';
import { useLocation, useNavigate } from 'react-router-dom';
import './StickyHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const StickyHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isSignIn, setIsSignIn] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false); // State for the sliding menu

    // Toggle the sliding menu
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <div className='centiblick-header'>
            <div className='d-flex align-items-center justify-content-between sticky-header common-container'>
                <div className='d-flex justify-content-between align-items-center w-50 gap-4'>
                    <img src={LandingLogo} alt="" className='cursor-pointer' onClick={() => navigate('/')} />
                    <div className='d-none d-md-flex gap-4'>
                        <h1 onClick={() => navigate('/pricing')}
                            className={`${(location.pathname === '/pricing' || location.pathname === '/request-demo') && 'active-component'}`}>
                            Plans & Pricing
                        </h1>
                        <h1 className={`d-flex gap-4 align-items-center ${location.pathname === '/add-on-features' && 'active-component'}`}
                            onClick={() => navigate('/add-on-features')}>
                            Add-On Features
                        </h1>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end right-navbar w-100'>
                    <button className="menu-toggle-btn d-md-none" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
                    </button>

                    <div className='d-flex gap-3 d-none d-md-flex'>
                        <button className='save-theme-btn shadow-none' onClick={() => navigate('/contact-sales')}>Contact Sales</button>
                        <button className='save-theme-btn shadow-none' onClick={() => navigate('/sign-in')}>Sign In</button>
                    </div>
                </div>
            </div>

            {isMenuOpen && (
                <div className='overlay' onClick={toggleMenu}></div>
            )}

            <div className={`slide-in-menu ${isMenuOpen ? 'open' : ''}`}>
                <div className='menu-content d-flex flex-column justify-content-between h-100'>
                    <div className='d-flex flex-column gap-4'>
                        <div className='d-flex justify-content-between'>
                            <img src={LandingLogo} alt="" className='cursor-pointer' onClick={() => navigate('/')} />
                            <button className="close-menu-btn" onClick={toggleMenu}>
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                            </button>
                        </div>
                        <div className='d-flex flex-column gap-4'>
                            <h1 onClick={() => { navigate('/pricing'); toggleMenu(); }} className={`${(location.pathname === '/pricing' || location.pathname === '/request-demo') && 'active-component'}`}>
                                Plans & Pricing
                            </h1>
                            <h1 onClick={() => { navigate('/add-on-features'); toggleMenu(); }} className={`${location.pathname === '/add-on-features' && 'active-component'}`}>
                                Add-On Features
                            </h1>
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-3'>
                        <button className='save-theme-btn shadow-none w-100' onClick={() => navigate('/contact-us')}>Contact Sales</button>
                        <button className='save-theme-btn shadow-none w-100' onClick={() => { setIsSignIn(true); toggleMenu(); }}>Sign In</button>
                    </div>
                </div>
            </div>

            {/* {isSignIn &&
                <>
                    <div className="modal-backdrop" />
                    <div
                        className={`modal fade ${isSignIn ? "show" : ""} signIn-modal`}
                        style={{ display: isSignIn ? "block" : "none" }}
                    >
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center modal-lg">
                            <div className="modal-content new-signin-modal" style={{ maxWidth: '700px', padding: '36px' }}>
                                <div className="modal-body p-0">
                                    <div className='sign-in-modal'>
                                        <h1>Welcome Back!</h1>
                                        <div className='select-div w-100'>
                                            <button className='save-theme-btn w-100' onClick={() => navigate('/provider/login')}>Providers</button>
                                            <button className='save-theme-btn w-100' onClick={() => navigate('/admin/login')}>Admins</button>
                                        </div>
                                        <p style={{ color: '#333333e6' }}>
                                            Don’t have a Telehealth account <span className='cursor-pointer' onClick={() => { navigate('/pricing'); setIsSignIn(false) }}>Signup</span> today
                                        </p>
                                        <img src={LandingLogo} alt="" width={125} height={70} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            } */}
        </div>
    );
};

export default StickyHeader;
