import React, { useEffect, useState } from 'react'

//css
import './CommonSecuritycontainer.scss'
import { useLocation } from 'react-router-dom';

interface SecurityProps {
    image: any;
    title: string;
}

const CommonSecuritycontainer = ({ image, title }: SecurityProps) => {

    const location = useLocation()

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setDeviceWidth(window.innerWidth);
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            className="common-security-trust d-flex justify-content-end"
            style={{
                background: `linear-gradient(90deg, #DEEAF3 55.97%, rgba(222, 234, 243, 0) 88.06%), url(${image})`,
                backgroundSize: location.pathname === '/enterprise-security' && deviceWidth <= 500 ? 'cover' : location.pathname === '/enterprise-security' ? 'contain' : deviceWidth <= 1024 ? 'cover' : 'auto'
            }}
        >
            <div className='w-100 common-container h-100'>
                <h1>{title}</h1>
            </div>
        </div>

    )
}

export default CommonSecuritycontainer