import React, { useEffect } from 'react'

//css
import '../EnterpriseSecurity.scss'
import CommonSecuritycontainer from '../CommonSecuritycontainer/CommonSecuritycontainer';
import images from '../../../../../Assets/Images';
import StickyHeader from '../../Header/StickyHeader';
import Footer from '../../Footer/Footer';

const HelpAndSupport = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='security-and-trust'>
            <StickyHeader />
            <div>
                <CommonSecuritycontainer image={images.HelpAndSupport} title='Help and Support' />
                <div className='security-and-trust-main-div common-container'>
                    {/* <div className='img-div w-100 d-flex align-items-center position-relative'>
                        <img src={images.HelpAndSupport} alt="" className='w-100' />
                        <h1 className='position-absolute' style={{ color: '#000' }}>Help and <br /> Support</h1>
                    </div> */}
                    <div className='main-content p-0'>
                        <h1>Help and Support</h1>
                        <div className='d-flex flex-column help-support' style={{ gap: "92px" }}>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                            <div className='d-flex help-details m-0' style={{ gap: "38px" }}>
                                <div className='w-100 m-0'>
                                    <h1>Porem</h1>
                                    <p className='mt-3'>Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis</p>
                                </div>
                                <div className='w-100 m-0'>
                                    <h1>Porem</h1>
                                    <p className='mt-3'>Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis</p>
                                </div>
                                <div className='w-100 m-0'>
                                    <h1>Porem</h1>
                                    <p className='mt-3'>Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis</p>
                                </div>
                            </div>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HelpAndSupport