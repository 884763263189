import React, { useEffect, useState } from 'react'

//css
import './CentiBlickHomePage.scss'
import StickyHeader from './Header/StickyHeader'
import images from '../../../Assets/Images'
import Footer from './Footer/Footer'
import { useNavigate } from 'react-router-dom'
import ResourceComponent from './ResourceComponent/ResourceComponent'

const CentiBlickHomePage = () => {

    const navigate = useNavigate()

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)


    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [navigate]);
    const Features = [
        { name: 'Compliance First', details: 'CentiBlick is designed to fully comply with HIPAA and HHS guidelines' },
        { name: 'Enhanced Security', details: 'Secure your telehealth sessions with end-to-end encryption, patient logins, & provider authentication' },
        { name: 'Seamless Integration', details: 'Easily integrate CentiBlick into your existing workflows with minimal disruption' },
        { name: 'Risk Mitigation', details: 'Stay ahead of regulatory changes & avoid penalties with a platform built for today’s healthcare environment' }
    ]

    const complianceData = [
        {
            title: "Secure Encrypted Appointment Links",
            title2: 'CentiBlick Appointment links have usernames and passwords',
            description:
                "Other platforms’ open links sent via text or emails without login requirements pose significant security risk. These links can be intercepted or accessed by unauthorized individuals, leading to potential breaches of protected health information (PHI).",
            imageUrl: images.FirstCompliance,
            buttonText: "Learn More",
            LearnTitle: '164.306 Security standards: General rules',
            subTitle1: 'General requirements. Covered entities and business associates must do the following:',
            details1: ['Ensure the confidentiality, integrity, and availability of all electronic protected health information the covered entity or business associate creates, receives, maintains, or transmits.',
                'Protect against any reasonably anticipated threats or hazards to the security or integrity of such information.',
                'Protect against any reasonably anticipated uses or disclosures of such information that are not permitted or required under subpart E of this part.',
                'Ensure compliance with this subpart by its workforce.'
            ],
        },
        {
            title: "Access Control & Authentication",
            title2: 'CentiBlick platform encounters are verified and recorded',
            description:
                "HIPAA mandates the verification of individuals accessing PHI. Other platforms' open links without authentication do not meet this requirement, increasing the risk of unauthorized access.",
            imageUrl: images.SecondCompliance,
            buttonText: "Learn More",
            LearnTitle: 'Technical safeguards',
            subTitle1: 'A covered entity or business associate must, in accordance with § 164.306:',
            // subTitle2:'',
            // details2: '',
            paraTitle: '(d) Standard:',
            paraTitleDetails: 'Person or entity authentication. Implement procedures to verify that a person or entity seeking access to electronic protected health information is the one claimed.'
        },
        {
            title: "Provider Registration & Identity Management",
            title2: 'CentiBlick Verifies and Secures All  Platform Providers',
            description:
                "As part of HIPAA compliance, it is essential to verify the identity and credentials of all providers using the telehealth platform. This ensures that only authorized and qualified individuals have access to sensitive patient information.",
            imageUrl: images.ThirdCompliance,
            buttonText: "Learn More",
            LearnTitle: '164.514 Other requirements relating to uses and disclosures of protected health information.',
            subTitle1: 'Standard: Verification requirements. Prior to any disclosure permitted by this subpart, a covered entity must:',
            details1: ['Except with respect to disclosures under § 164.510, verify the identity of a person requesting protected health information and the authority of any such person to have access to protected health information under this subpart, if the identity or any such authority of such person is not known to the covered entity; and', 'Obtain any documentation, statements, or representations, whether oral or written, from the person requesting the protected health information when such documentation, statement, or representation is a condition of the disclosure under this subpart.'],
            subTitle2: 'Implementation specifications: Verification—',
            details2: ['(i) Conditions on disclosures. If a disclosure is conditioned by this subpart on particular documentation, statements, or representations from the person requesting the protected health information, a covered entity may rely, if such reliance is reasonable under the circumstances, on documentation, statements, or representations that, on their face, meet the applicable requirements.', '(A) The conditions in § 164.512(f)(1)(ii)(C) may be satisfied by the administrative subpoena or similar process or by a separate written statement that, on its face, demonstrates that the applicable requirements have been met.', '(B) The documentation required by § 164.512(g)(2) may be satisfied by one or more written statements, provided that each is appropriately dated and signed in accordance with § 164.512(g)(2)(v).'],
        },
        {
            title: "End-to-End Encryption",
            title2: 'CentiBlick Ensures both the Transmission and Sessions are fully encrypted',
            description:
                "While other platforms’ telehealth sessions might be encrypted, the transmission of open links is not, exposing sensitive information to potential breaches and unauthorized patient and provider data access.",
            imageUrl: images.ForthCompliance,
            buttonText: "Learn More",
            LearnTitle: '164.312 Technical safeguards',
            subTitle1: 'A covered entity or business associate must, in accordance with 154.305:',
            paraTitle: 'Standard:',
            paraTitleDetails: 'Transmission security. Implement technical security measures to guard against unauthorised access to electronic protected health information tat is being transmitted over an electronic communications network.'
        },
    ];

    const pricingData = [
        {
            title: "Individual Provider",
            description:
                "Our individual provider platform offers a secure and encrypted solution for your telehealth needs. Enjoy identity protection, verified patient profiles, detailed encounter summaries, and a modern best-in-class experience for both you and your patients.",
            imageUrl: images.EmployerWebsitePicture,
            buttonText: "Learn More",
            use: 'Unlimited use',
            price: "$35",
            duration: '/month',
            url: '/pricing'
        },
        {
            title: "Clinic Platform",
            description:
                "Our Clinic Platform includes all individual provider features plus a state-of-the-art admin center. Enjoy provider room directories, detailed patient and provider profiles, encounter summaries, and comprehensive platform analytics for a streamlined, modern experience.",
            imageUrl: images.ClinicPictureOption,
            buttonText: "Learn More",
            use: 'Unlimited use',
            price: "$50",
            duration: '/provider/month',
            url: '/pricing'
        },
        {
            title: "Add-On Features",
            description:
                "Start with the core features and expand with add-on functionalities as your needs grow. Access HIPAA Compliant Payments, 2-way EHR Integration, Flexcare virtual + location scheduling, patient appointments, 360 Medical Records, AI charting and so much more.",
            imageUrl: images.PlatformUpgradesImage,
            buttonText: "Get Quote",
            price: "",
            url: '/contact-sales'
        },
    ];

    const securityData = [
        { title: 'HIPPA Compliant' },
        { title: 'GDPR Compliant' },
        { title: 'HiTrust Compliant' },
        { title: 'SOC2 Certified' },
        { title: 'FedRAMP Certified' },
        { title: 'End-to-end Encryption' },
    ]

    const [selectedCompliance, setSelectedCompliance] = useState(complianceData[0]);
    const [animationClass, setAnimationClass] = useState('');

    const [learnMore, setLearnMore] = useState<boolean>(false)

    const handleComplianceChange = (data: any, index: any) => {
        setLearnMore(false)
        const currentIndex = complianceData.findIndex(item => item.title === selectedCompliance.title);

        if (selectedCompliance.title !== data.title) {
            if (index > currentIndex) {
                setAnimationClass('slide-in-right');
            } else if (index < currentIndex) {
                setAnimationClass('slide-in-left');
            }

            setSelectedCompliance(data);
            setTimeout(() => {
                setAnimationClass('');
            }, 500);
        }
    };

    const handleLearnMore = () => {
        setLearnMore(true)
    }
    const handleBack = () => {
        setLearnMore(false)
    }

    return (
        <div className='centiblick-home-page'>
            <StickyHeader />
            <div style={{ backgroundColor: "#fff" }}>
                <div className='hipaa-compliant position-relative d-flex gap-4 position-relative common-container'>
                    <img src={images.PricingBg} alt="" className='first-bg' />
                    <img src={images.PricingBg} alt="" className='second-bg' />
                    <img src={images.PricingBg} alt="" className='third-bg' />
                    <img src={images.PricingBg} alt="" className='forth-bg' />
                    <img src={images.PricingBg} alt="" className='fifth-bg' />
                    <img src={images.PricingBg} alt="" className='sixth-bg' />
                    <div className='z-1'>
                        <h1 className='my-4'>
                            HIPAA & HHS Compliant
                        </h1>
                        <h2>
                            Telehealth Platform
                        </h2>
                        <p className='my-4'>
                            CentiBlick provides a secure, compliant telehealth platform that integrates seamlessly into your practice, reducing legal risks while enhancing patient care
                        </p>
                        <div className='mt-3 d-flex gap-5'>
                            <button className='rounded-save-theme-btn' onClick={() => navigate('/pricing')}>Get Started</button>
                            <button className='save-theme-btn' onClick={() => navigate('/request-demo')}>Request a Demo</button>
                        </div>
                    </div>
                    <div className='z-1 w-100'>
                        <img src={images.HomePageProvider} alt="" className='provider-img' />
                        <p className='mt-3 text-center' style={{ color: "#516cd3" }}>Telehealth powered by CentiBlick</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#fff" }}>
                <div className='centiblick-features common-container'>
                    <div className='row'>
                        {
                            Features?.map((data: any) => (
                                <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12 d-flex mt-4'>
                                    <div className='feature-details'>
                                        <h1 className='mb-3'>{data.name}</h1>
                                        <p>{data.details}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5", boxShadow: "17px 20px 44px 1px rgba(160, 160, 160, 0.2509803922)" }}>
                <div className='compliance-container w-100 common-container'>
                    <h1 className='compliance-header'>Must Have Compliance Features</h1>
                    <div className='w-100 d-flex justify-content-end align-items-center position-relative'>
                        <div className={`position-absolute start-0 z-1 ${deviceWidth < 1025 && 'd-none'}`} style={{ minWidth: "39%" }}>
                            {complianceData.map((data, index) => (
                                <div
                                    className='compliance-data'
                                    key={index}
                                    onClick={() => handleComplianceChange(data, index)}
                                    style={selectedCompliance.title === data.title ? { background: "#516cd3", color: "#fff", fontWeight: "700", padding: "36px" } : {}}
                                >
                                    {data.title}
                                </div>
                            ))}
                        </div>
                        <div className={`compliance-div d-flex gap-3 ${animationClass}`}>
                            <img src={selectedCompliance.imageUrl} alt="" />
                            <div className='compliance-details'>
                                {learnMore ?
                                    <div className='compliance-height'>
                                        <h1>{selectedCompliance?.LearnTitle}</h1>
                                        <div>
                                            <h2 className='my-3'>{selectedCompliance?.subTitle1}</h2>
                                            {
                                                selectedCompliance?.paraTitle ?
                                                    <div className='learn-details'>
                                                        <span>{selectedCompliance?.paraTitle} {' '} </span>{selectedCompliance?.paraTitleDetails}
                                                    </div>
                                                    :
                                                    <ol style={{ listStyleType: "decimal" }} className='ps-4'>
                                                        {
                                                            selectedCompliance?.details1?.map((details) => (
                                                                <li>{details}</li>
                                                            ))
                                                        }
                                                    </ol>
                                            }
                                        </div>
                                        {
                                            selectedCompliance?.subTitle2 &&
                                            <div>
                                                <h2 className='my-3'>{selectedCompliance?.subTitle2}</h2>
                                                <ol style={{ listStyleType: "decimal" }} className='ps-4'>
                                                    {
                                                        selectedCompliance?.details2?.map((details) => (
                                                            <li>{details}</li>
                                                        ))
                                                    }
                                                </ol>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className='compliance-height'>
                                        <h1>{selectedCompliance.title2}</h1>
                                        <p className='mt-3'>{selectedCompliance.description}</p>
                                    </div>
                                }
                                <button className='save-theme-btn w-75 px-0' onClick={learnMore ? handleBack : handleLearnMore}>{learnMore ? "Back" : selectedCompliance.buttonText}</button>
                            </div>
                        </div>
                    </div>
                    {
                        deviceWidth < 1025 &&
                        <div className='d-flex gap-3'>
                            {complianceData.map((data, index) => (
                                <div className='control-tooltip'>
                                    <div
                                        className='responsive-compliance-data cursor-pointer'
                                        key={index}
                                        onClick={() => handleComplianceChange(data, index)}
                                        style={selectedCompliance.title === data.title ? { background: "#516cd3", width: "35px", borderRadius: "50px" } : {}}
                                    >
                                    </div>
                                    <span className="control-tooltip-text">{data.title}</span>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
            <div style={{ background: 'linear-gradient(180deg, rgba(226, 232, 254, 0.00) -4.04%, #E2E8FE 64.63%)' }}>
                <div className='pricing-container common-container'>
                    <h1 className='text-center'>We Make Compliance Easy: <br /> Just Swap The Link!</h1>
                    <div className='pricing-div mt-5'>
                        {pricingData.map((item, index) => (
                            <div className="pricing-item w-100" key={index}>
                                <img src={item.imageUrl} alt={item.title} className="pricing-image" />
                                <div className='d-flex flex-column justify-content-evenly align-items-center'>
                                    <div>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center w-100 pricing-button'>
                                        <h2 className='price'>{item.price} <span>{item.duration}</span></h2>
                                        <button className='save-theme-btn' onClick={() => window.open(item.url, '_blank')}>{item.buttonText}</button>
                                    </div>
                                    <p className='useage'>{item.use}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='security-main-div' style={{ backgroundColor: "#fff", boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.1019607843)" }}>
                <div className='security-container common-container'>
                    <div className='security-div w-50'>
                        <h1 className=''>Enterprise Security</h1>
                        <div className='security-type mt-5'>
                            {
                                securityData.map((item, index) => (
                                    <div className='d-flex align-items-center security-details'
                                        onClick={() => navigate(`/enterprise-security`, { state: { sectionId: item.title } })}>
                                        <img src={images.SecurityCheck} alt="" />                                        
                                        <h1>{item.title}</h1>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ResourceComponent />
            </div>
            <Footer />
        </div>
    )
}

export default CentiBlickHomePage