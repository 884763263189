import React, { useEffect } from 'react';

//css
import '../EnterpriseSecurity.scss';
import CommonSecuritycontainer from '../CommonSecuritycontainer/CommonSecuritycontainer';
import images from '../../../../../Assets/Images';
import StickyHeader from '../../Header/StickyHeader';
import Footer from '../../Footer/Footer';
import { TermsOfUseData } from '../../../commonSecurityData';

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='security-and-trust'>
      <StickyHeader />
      <div>
        <CommonSecuritycontainer image={images.TermsOfUse} title='Terms of use' />
        <div className='security-and-trust-main-div common-container'>
          {/* <div className='img-div w-100 d-flex align-items-center'>
            <img src={images.TermsOfUse} alt="" className='w-100' />
          </div> */}
          <div className='terms-of-use mt-4 p-0'>
            {
              TermsOfUseData.map((terms, index) => (
                <div key={index} className="security-section">
                  <div className='security-title'>
                    <h1>{terms.title}</h1>
                  </div>
                  {
                    terms?.subTitle &&
                    <div className='security-para mb-2'>
                      <p>{terms.subTitle}</p>
                    </div>
                  }
                  {terms.type === 'list' && Array.isArray(terms.details) ? (
                    <ol>
                      {terms.details.map((item, subIndex) => (
                        <li key={subIndex}>
                          {terms.ListTitle && terms.ListTitle[subIndex] ? (
                            <strong>{terms.ListTitle[subIndex]}: </strong>
                          ) : null}
                          {item.text}
                          {item.subDetails && Array.isArray(item.subDetails) && (
                            <ul>
                              {item.subDetails.map((subItem, subItemIndex) => (
                                <li key={subItemIndex}>{subItem}</li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ol>
                  ) : (
                    // Render a paragraph if details is a string
                    typeof terms.details === 'string' && (
                      <div className="security-para">
                        <p>{terms.details}</p>
                      </div>
                    )
                  )}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
